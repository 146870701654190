import apiClient, { del, get, patch } from './utils/api-client'
import {
  SaivaUser,
  UserCapability,
  UserLoginState,
  UserPermission,
  OrgProductFeature
} from '../types/user-types'
import { generateFilterUrl, PaginatedResponse, parseApi, RequestFilter } from './utils/api-utils'

export namespace UserResponse {

  export interface OrgItem {
    id: string,
    name: string,
    partner_id: string | null,
    roles: OrgRole[],
    last_active_at: Date,
    permissions: string[],
    product_features: string[]
  }

  export interface OrgRole {
    role_name: string,
    role_category: string
  }

  export interface Role {
    name: string,
    description: string
  }

  export interface Title   {
    name: string,
    label: string
  }

  export interface LoggedUser {
    id: number,
    email: string,
    title: string,
    name: string,
    is_superuser: boolean,
    role_category: string[]
    orgs?: OrgItem[],
  }

  export interface Region {
    all_facilities: boolean
    facility_ids: number[]
    region_id: number
  }

  export interface Regions {
    full_access: boolean
    items: any
  }

  export interface User {
    id: number,
    email: string,
    title: string,
    name: string,
    last_active_at: Date,
    is_superuser: boolean,
    roles: {role_name: string, enabled: boolean}[],
    regions: Regions
    capabilities: UserCapability[]
  }

  export namespace Update {
    export interface User {
      email: string,
      title: string,
      name: string,
      roles: {role_name: string, enabled?: boolean}[],
      regions: Regions
      capabilities: string[]
    }
    export interface Invitation {
      email: string,
      title: string,
      name: string,
      roles: {role_name: string, enabled?: boolean}[],
      regions: Regions
      capabilities: string[]
    }
  }
}

const parseUser = {
  UserOrg: (response: UserResponse.OrgItem) : SaivaUser.OrgItem => {
    return {
      id: response.id,
      name: response.name,
      partner_id: response.partner_id,
      role: response.roles[0].role_name,
      last_active_at: response.last_active_at,
      permissions: response.permissions.map(permission => permission as UserPermission),
      productFeatures: response.product_features.map(feature => feature as OrgProductFeature)
    }
  },
  LoggedUser: (response: UserResponse.LoggedUser) : SaivaUser.LoggedUser => {
    const user: SaivaUser.LoggedUser = {
      currentOrg: {id: "-1" , name: "", partner_id: null, role: "", last_active_at: new Date(), permissions: [], productFeatures: []},
      currentMedicalSupplyOrg: {id: "-1" , name: "", partner_id: null, role: "", last_active_at: new Date(), permissions: [], productFeatures: []},
      loginState: UserLoginState.Loading,
      email: response.email,
      id: response.id,
      name: response.name,
      title: response.title,
      isSuperuser: response.is_superuser,
      role_category: response.role_category,
      currentRole: '',
    }
    if(response.orgs) user.orgs = response.orgs.map(org => parseUser.UserOrg(org))
    return user
  },
  User: (response: UserResponse.User, roles?: {name: string, description: string}[]) : SaivaUser.User => {
    return {
      id: response.id,
      email: response.email,
      title: response.title,
      name: response.name,
      is_superuser: response.is_superuser,
      last_active_at: response.last_active_at,
      roles: response.roles.map(role => {
        const found = roles?.find(r => r.name === role.role_name)
        if (found) return {...found , enabled: role.enabled}
        return { name: role.role_name, enabled: true, description: "Unknown" }
      }),
      regions: response.regions,
      capabilities: response.capabilities.map(capability => capability as UserCapability)
    }
  },
  Users: (response: UserResponse.User[], roles?: {name: string, description: string}[]) : SaivaUser.User[] => {
    return response.map(user => parseUser.User(user, roles))
  }
}

const getLoggedUser = async (): Promise<SaivaUser.LoggedUser | undefined> => {
  const res = await get<UserResponse.LoggedUser, SaivaUser.LoggedUser>('v2/users/me', {parser: parseUser.LoggedUser})
  if(res) res.orgs?.sort((a,b) => {
    if(a.name > b.name) return 1
    if(a.name < b.name) return -1
    return 0
  })
  return res
}

const updateLoggedUser = async (payload: {title: string, name: string}): Promise<SaivaUser.LoggedUser | undefined> => {
  return await patch<UserResponse.LoggedUser, SaivaUser.LoggedUser>('v2/users/me', {parser: parseUser.LoggedUser, payload})
}

export interface UserFilter extends RequestFilter {
  roles?: string[]
  facilityIds?: number[]
  capabilities?: UserCapability[]
  regions?: number[]
  active?: boolean
  enabled?: boolean
  status?: string[]
  role_category?: string
}

const getUsers = async (orgId: string, filter: UserFilter) : Promise<PaginatedResponse<SaivaUser.User> | undefined> => {
  const url = generateFilterUrl("v2/orgs/org_id/users", [{id: "org_id", value: orgId}], filter)
  const roles = await getRoles(orgId)
  return await get<UserResponse.User, SaivaUser.User>(url, {parser: (r) => parseUser.User(r, roles), paginated: true})
}

const getUser = async (orgId: string, userId: number) : Promise<SaivaUser.User | undefined> => {
  const params = [{id: "org_id", value: orgId}, {id: "user_id", value: userId.toString()}]
  const url = generateFilterUrl("v2/orgs/org_id/users/user_id", params)
  const roles = await getRoles(orgId)
  return await get<UserResponse.User, SaivaUser.User>(url, {parser: (r) => parseUser.User(r, roles)})
}

const deleteUser = async (orgId: string, userId: number) => {
  const params = [{id: "org_id", value: orgId}, {id: "user_id", value: userId.toString()}]
  const url = generateFilterUrl("v2/orgs/org_id/users/user_id", params)
  return await del<UserResponse.User>(url)
}

const updateUser = async (orgId: string, userId: number, payload: UserResponse.Update.User) : Promise<SaivaUser.User | undefined> => {
  const params = [{id: "org_id", value: orgId}, {id: "user_id", value: userId.toString()}]
  const url = generateFilterUrl("v2/orgs/org_id/users/user_id", params)
  const roles = await getRoles(orgId)
  return await patch<UserResponse.User, SaivaUser.User>(url, {payload, parser:  (r) => parseUser.User(r, roles)})
}

const getRoles = async (orgId: string) : Promise<SaivaUser.Role[] | undefined> => {
  const url = generateFilterUrl("/v2/orgs/org_id/roles", [{id: "org_id", value: orgId}])
  return await get<UserResponse.Role[], SaivaUser.Role[]>(url)
}

const getTitles = async (orgId: string, filter: UserFilter) : Promise<SaivaUser.Title[] | undefined> => {
  const url = generateFilterUrl("/v2/orgs/org_id/titles", [{id: "org_id", value: orgId}], filter)
  return await get<UserResponse.Title[], SaivaUser.Title[]>(url)
}

const UserService = {
  getLoggedUser,
  updateLoggedUser,
  getUsers,
  getUser,
  deleteUser,
  updateUser,
  getRoles,
  getTitles
}

export default UserService
