import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'context/UserContext'
import { ApiError, ISaivaDetailedUser } from 'services/api'
import SaivaIcon from 'components/saivaIcon'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import TooltipBootstrap from 'react-bootstrap/Tooltip'
import { Tooltip } from 'antd'
import { showErrorToast, showSuccessToast } from 'utils'
import { mixpanelInstance } from 'utils/mixpanel'
import UserService, { UserFilter } from 'services/user-service'
import FacilityService from 'services/facility-service'
import { SaivaFacility } from 'types/facility-types'
import { parseApi, SaivaSort, SortDirection } from 'services/utils/api-utils'
import { UserPermission } from 'types/user-types'
import { SaivaUser } from 'types/user-types'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import FormModal from './CreateUser/Modal'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import { UserCapability } from 'types/user-types'
import scssVariables from 'styles/variables.module.scss'
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg'
import { ReactComponent as ResizeIcon } from 'assets/icons/resize-small-vertical.svg'
import SaivaPagination from 'components/Tables/SaivaPagination/SaivaPagination'
import { Dropdown, Menu, Space, Modal } from 'antd'
import styles from './Users.module.scss'
import { ReactComponent as MenuVertical } from 'assets/icons/menu-vertical.svg'
import { ReactComponent as DisableIcon } from 'assets/icons/disable.svg'
import { ReactComponent as EnableIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross-big.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import RegionService from "services/region-service";
import SaivaCustomSelect from 'components/SaivaForm/SaivaMultiSelect/SaivaCustomSelect'
import SaivaRadioButton from 'components/SaivaForm/SaivaRadioButton/saivaRadioButton'
import AccessControl from 'components/AccessControl/AccessControl'
import Gradient from 'assets/icons/Gradient.gif'

export interface SelectOption {
  label: string
  value: string | number
  color?: string
}

interface Props {
  filter?: UserFilter
}

export default function AdminUsers({filter}: Props) {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const navigate = useNavigate()
  const [users, setUsers] = useState<Array<ISaivaDetailedUser>>([])
  const [editingUser, setEditingUser] = useState<SaivaUser.User | undefined>(undefined)
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const [availableFacilities, setAvailableFacilities] = useState<Array<SaivaFacility.Item>>([])
  const [facilitiesOptions, setFacilitiesOptions] = useState<Array<{value: number, label: string, parent: number}>>([])
  const [facilitiesOptionsForFilter, setFacilitiesOptionsForFilter] = useState<Array<{value: number, label: string, parent: number}>>([])
  const [roles, setRoles] = useState<Array<{value: string, label: string, role_category: string}>>([])
  const [regions, setRegions] = useState<Array<{value: number, label: string, children: any}>>([])
  const [userFilter, setUserFilter] = useState<UserFilter | undefined>(filter)
  const [tableSort, setTableSort] = useState<SaivaSort | undefined>(undefined)
  const inviteUserTitle = t('users.userForm.invite.title')
  const editUserTitle = t('users.userForm.edit.title')
  const [userFormModalTitle, setUserFormModalTitle] = useState<string>(inviteUserTitle)
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [saivaUsers, setSaivaUsers] = useState<SaivaUser.User[] | undefined>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [itemCount, setItemCount] = useState(0)
  const [openError, setOpenError] = useState<boolean>(false)

  useEffect(() => {
    fetchUsers()
  }, [currentPage, pageSize, userContext.currentOrg.id, userFilter, tableSort])

  useEffect(() => {
    setUserFilter(filter)
  }, [userContext.currentOrg.id, filter])

  const fetchUsers = async () => {
    let items: number = 0;
    setRequestInFlight(true)
    const res = await UserService.getUsers(userContext.currentOrg.id, {...userFilter, page: currentPage, size: pageSize, sort: tableSort, role_category: userContext.currentRole})
    setSaivaUsers(res?.items)
    const lastPage = Math.ceil(res?.total ? res.total / res.size : 1)
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    setPageSize(pageSize)
    setItemCount(res?.total ? res?.total : 0)
    setUsers((res?.items.map(user => parseApi.UserToDetailedUser(user)) ?? []).filter((u): u is ISaivaDetailedUser => !!u))
    setRequestInFlight(false)
    items = res?.total ?? 0
  }

  const onChangePage = (newCurrent: number) => {
    setCurrentPage(newCurrent)
  }

  const onShowSizeChange = (pageSize: number) => {
    const lastPage = Math.ceil(itemCount / pageSize)
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    setPageSize(pageSize)
  }

  const fetchFacilities = async () => {
    const res = await FacilityService.getAllFacilities(userContext.currentOrg.id)
    setAvailableFacilities(res ? res : [])
    setFacilitiesOptions(res? res.map(item => {return {value: item.id, label: item.name, parent: item.region_id}}) : [])
    setFacilitiesOptionsForFilter(res? res.map(item => {return {value: item.id, label: item.name, parent: item.region_id,}}) : [])
  }

  const fetchRegions = async () => {
    try {
      const regions = await RegionService.getRegions(userContext.currentOrg.id)
      if (regions) setRegions(regions.map((item) => {
        return {value: item.id, label: item.name, children: item.facilities.map((g) => {return {value: g.id, label: g.name,}}) }
      }))
    } catch (e) {
      setRegions([])
    }
  }

  const fetchRoles = async () => {
    try {
      const roles = await UserService.getRoles(userContext.currentOrg.id)
      if (roles) setRoles(roles
        .filter(
          (role) => role.role_category === userContext.currentRole
        )
        .map((item) => {
          return {value: item.name, label: item.description, role_category: item.role_category}
        }))
    } catch (e) {
      setRoles([])
    }
  }

  useEffect(() => {
    fetchFacilities()
    fetchRoles()
    fetchRegions()
  }, [userContext.currentOrg])

  const editUser = (user: SaivaUser.User | undefined) => {
    setUserFormModalTitle(editUserTitle)
    setEditingUser(user)
    setShowCreateUserModal(true)
  }

  const disableUser = async (user: SaivaUser.User | undefined) => {
    if (user) {
      await UserService.updateUser(
        userContext.currentOrg.id,
        user.id,
        { ...user,
          roles: user.roles.map((r) => {
            return {role_name: r.name, enabled: !r.enabled}
          })
        }
      )
        .then((resp) => {
          showSuccessToast(
            t('userForm.updateUserSuccessText', { email: user.email })
          )
          fetchUsers()
          mixpanelInstance.usersDisable({ ...user,
            roles: user.roles.map((r) => {
              return {role_name: r.name, enabled: !r.enabled}
            })
          })
        })
        .catch((err) => {
          if (err.name === 'ApiError') {
            if (err.errorCode === 'backend.feature_limit_is_reached') {
              setOpenError(true)
            }
          } else {
            if (err.errorCode === 'backend.feature_limit_is_reached') {
              setOpenError(true)
            }
          }
          throw err
        })
    }
  }

  const createUser = () => {
    setUserFormModalTitle(inviteUserTitle)
    setEditingUser(undefined)
    setShowCreateUserModal(true)
  }

  const handleSortClick = (propertyName: string) => {
    const currentDirection = tableSort?.direction ?? SortDirection.Descending
    mixpanelInstance.usersSortTable(propertyName, currentDirection)
    setTableSort({
      property: propertyName,
      direction: currentDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending
    })
  }

  const removeUser = async (user: ISaivaDetailedUser) => {
    const result = confirm(t('users.removeUser.confirmationText', { email: user.email }))
    if (result === false) {
      return
    }
    try {
      const res = await UserService.deleteUser(userContext.currentOrg.id, user.id)
      showSuccessToast(t('users.removeUser.successText', { email: user.email }))
    } catch (err) {
      if (err instanceof ApiError) {
        showErrorToast(t(err.errorCode))
      } else {
        showErrorToast(t("genericError"))
      }
    }
    fetchUsers()
  }

  const makeActionMenu = (user: ISaivaDetailedUser) => {
    return (
      <Dropdown
        trigger={['click']}
        overlayClassName={styles.dropdown}
        dropdownRender={(menu) => (
          <Menu>
            <AccessControl userPermissions={[UserPermission.USERS_EDIT]}>
              <Menu.Item
                onClick={() => editUser(saivaUsers?.filter(item => item.id == user.id)[0])}
                className={`${styles.menuItem}`}
              >
                <EditIcon style={{ marginRight: '12px' }} />
                {t('users.table.action.edit.text')}
              </Menu.Item>
            </AccessControl>
            <AccessControl userPermissions={[UserPermission.USERS_EDIT]}>
              <Menu.Item
                onClick={() => disableUser(saivaUsers?.filter(item => item.id == user.id)[0])}
                className={`${styles.menuItem}`}
              >
                {user.permissions.role.enabled ? 
                  <>
                    <DisableIcon style={{ marginRight: '12px' }} />
                    {t('users.table.action.disable.text')}
                  </> : <>
                    <EnableIcon style={{ marginRight: '12px' }} />
                    {t('users.table.action.enable.text')}
                  </>}
              </Menu.Item>
            </AccessControl>
            <AccessControl userPermissions={[UserPermission.USERS_EDIT]}>
              <Menu.Item
                onClick={() => removeUser(user)}
                className={`${styles.deleteMenuItem} ${styles.menuItem}`}
              >
                <DeleteIcon style={{ marginRight: '12px' }} />
                {t('users.table.action.delete.text')}
              </Menu.Item>
            </AccessControl>
          </Menu>
        )}
      >
        <Space>
          <MenuVertical className={styles.menuIcon}/>
        </Space>
      </Dropdown>
    )
  }

  const formatFacilities = (user: ISaivaDetailedUser) => {
    const facilityCount = user.permissions.regions.full_access 
      ? 'All'
      : user.permissions.facilities.length
    const facilities = facilityCount == 1 ? t('users.facilities.singular') : t('users.facilities.plural')
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => renderTooltip(user, props)}
      >
        <span>
          {facilityCount + ' ' + facilities}
        </span>
      </OverlayTrigger>
    )
  }

  const formatRegions = (user: ISaivaDetailedUser) => {
    const regionCount = user.permissions.regions.full_access 
      ? 'All'
      : user.permissions.regions.items.length
    const regions = regionCount == 1 ? t('users.regions.singular') : t('users.regions.plural')
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => renderTooltipRegions(user, props)}
      >
        <span>
          {regionCount + ' ' + regions}
        </span>
      </OverlayTrigger>
    )
  }

  const formatCapabilities = (user: ISaivaDetailedUser) => {
    return (
      <>
        {user.capabilities.emailNotifications && <span className="badge bg-email me-2">{t('users.capability.email')}</span>}
        {user.capabilities.pushNotifications && <span className="badge bg-push me-2">{t('users.capability.push')}</span>}
        {user.capabilities.webAppLogin && <span className="badge bg-web me-2">{t('users.capability.webapp')}</span>}
        {user.capabilities.mobileAppLogin && <span className="badge bg-mobile me-2">{t('users.capability.mobileapp')}</span>}
      </>
    )
  }

  const renderTooltip = (user: ISaivaDetailedUser, props) => {
    const facilityIds = user.permissions.regions.full_access 
      ? facilitiesOptions.map((i) => {return i.value})
      : user.permissions.facilities
    return (
      <TooltipBootstrap id="button-tooltip" {...props}>
        {facilityIds
          .map((facilityId) => {
            const found = availableFacilities.find((f) => f.id === facilityId)
            if (found) {
              return found.name
            } else {
              return t('users.tooltip.unknown')
            }
          })
          .map((f) => {
            return (
              <span key={f}>
                {f}<br />
              </span>
            )
          })
        }
      </TooltipBootstrap>
    )
  }

  const renderTooltipRegions = (user: ISaivaDetailedUser, props) => {
    const rgionIds = user.permissions.regions.full_access 
      ? regions.map((i) => {return {region_id: i.value}})
      : user.permissions.regions.items
    return (
      <TooltipBootstrap id="button-tooltip" {...props}>
        {rgionIds
          .map((regionId) => {
            const found = regions.find((f) => f.value === regionId.region_id)
            if (found) {
              return found.label
            } else {
              return t('users.tooltip.unknown')
            }
          })
          .map((f) => {
            return (
              <span key={f}>
                {f}<br />
              </span>
            )
          })
        }
      </TooltipBootstrap>
    )
  }

  const makeUserRow = (user: ISaivaDetailedUser) => {
    const userRow = (
      <tr key={user.id} className={`${!user.permissions.role.enabled && styles.disabled}`}>
        <td>
          <Tooltip 
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={user.name}
          >
            <div className={`elipsis`} style={{maxWidth: '140px'}}>
              {user.name}
            </div>
          </Tooltip>
        </td>
        <td>
        <Tooltip 
          arrow={false}
          overlayInnerStyle={{
            background: '#112443',
            textAlign: 'left'
          }}
          title={user.email}
        >
          <div className={`elipsis`} style={{width: '120px'}}>
            {user.email}
          </div>
        </Tooltip>
        </td>
        <td>{user.permissions.role.description}</td>
        <td>
          <Tooltip 
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={user.title}
          >
            <div className={`elipsis`} style={{width: '120px'}}>
              {user.title}
            </div>
          </Tooltip>
        </td>
        <td>{formatCapabilities(user)}</td>
        <td style={{minWidth: '110px'}}>{formatFacilities(user)}</td>
        <td style={{minWidth: '110px'}}>{formatRegions(user)}</td>
        <td style={{minWidth: '110px'}}>{parseDate(user.last_active_at)}</td>
        <AccessControl userPermissions={[UserPermission.USERS_EDIT, UserPermission.USERS_EDIT]}>
          <td>
            {makeActionMenu(user)}
          </td>
        </AccessControl>
      </tr>
    )
    return (
      <>
        {!user.permissions.role.enabled ? 
        <Tooltip 
          arrow={false}
          overlayInnerStyle={{
            background: '#112443',
            textAlign: 'left'
          }}
          title={'Disabled User'}
        >
          {userRow}
        </Tooltip> : userRow}
      </>
    )
  }

  const parseDate = (date) => {
    if (date) {
      date = new Date(date)
      let day = date.getDate().toString()
      let month = (date.getMonth() + 1).toString()
      let year = date.getFullYear()
      if (Number(day) < 10) {
        day = '0' + day
      }
      if (Number(month) < 10) {
        month = `0${month}`
      }
      return `${month}/${day}/${year}`
    }
  }

  const handleClose = () => {
    fetchUsers()
    setShowCreateUserModal(false)
  }

  const columnCount = (userContext.hasPermission(UserPermission.USERS_EDIT) || userContext.hasPermission(UserPermission.USERS_EDIT)) ? 9 : 8

  const makeSortableTableHeadCell = (label: string, propertyName?: string) => {
    return (
      <th onClick={() => propertyName && handleSortClick(propertyName)} style={{whiteSpace: 'nowrap', cursor: 'pointer', lineHeight: '24px'}}>
        {label}
        {tableSort && tableSort.property === propertyName && <ResizeIcon />}
      </th>
    )
  }

  const CAPABILITY_EMAIL: SelectOption = {
    value: UserCapability.EMAIL_NOTIFICATION,
    label: t('users.capability.email'),
    color: scssVariables.capabilityEmail
  }
  const CAPABILITY_PUSH: SelectOption = {
    value: UserCapability.PUSH_NOTIFICATION,
    label: t('users.capability.push'),
    color: scssVariables.capabilityPush
  }
  const CAPABILITY_WEB: SelectOption = {
    value: UserCapability.WEB_APP_LOGIN,
    label: t('users.capability.webapp'),
    color: scssVariables.capabilityWeb
  }
  const CAPABILITY_MOBILE: SelectOption = {
    value: UserCapability.MOBILE_APP_LOGIN,
    label: t('users.capability.mobileapp'),
    color: scssVariables.capabilityMobile
  }

  const capabilities =
  userContext.currentRole === 'medical_supply'
    ? [CAPABILITY_WEB]
    : [CAPABILITY_EMAIL, CAPABILITY_PUSH, CAPABILITY_WEB, CAPABILITY_MOBILE]

  const filters = (
    <div style={{ minWidth: '300px', padding: '16px 16px 0px 16px', maxWidth: '300px' }}>
    <b style={{ fontSize: '14px' }}>{t('users.table.filters.title')}</b>
    <div style={{ margin: '12px 0px' }}>
      <SaivaCustomSelect
        value={userFilter?.facilityIds ? userFilter.facilityIds : []}
        name="regions"
        label={t('invitedUsers.form.label.access_facilities')}
        reset={() => setUserFilter({...userFilter, facilityIds: []})}
        values={facilitiesOptionsForFilter}
        parents={regions}
        disabled={false}
        onChange={(e) => {
          setUserFilter({...userFilter, facilityIds: e})
        }}
      />
    </div>
    <div style={{ margin: '12px 0px' }}>
      <SaivaSelect
        value={userFilter?.capabilities}
        icon={null}
        label={t('users.table.filters.label.capabilities')}
        options={capabilities}
        placeholder={t('users.table.filters.placeholder.capabilities')}
        multiselect={true}
        name="capabilities"
        reset={() => {
          setUserFilter({...userFilter, capabilities: []})
        }}
        onChange={(e) => {
          setUserFilter({...userFilter, capabilities: e})
        }}
      />
    </div>
    <div style={{ margin: '12px 0px' }}>
      <SaivaSelect
        value={userFilter?.roles}
        icon={null}
        label={t('users.table.filters.label.role')}
        options={roles}
        placeholder={t('users.table.filters.placeholder.role')}
        multiselect={true}
        name="roles"
        reset={() => {
          setUserFilter({...userFilter, roles: []})
        }}
        onChange={(e) => {
          setUserFilter({...userFilter, roles: e})
        }}
      />
    </div>
    <div style={{ margin: '12px 0px', display: 'flex' }}>
      <SaivaRadioButton
        value={
          userFilter && 
          userFilter?.enabled == false? ['enabled'] :
          []
        }
        name="enabled"
        width={'80px'}
        height={24}
        inputType="checkbox"
        returnType="string"
        label={''}
        values={[{ value: 'enabled', label: 'Disabled' }]}
        onChange={(e) => {
          if (e.includes('enabled')) {
            setUserFilter({ ...userFilter, enabled: false })
          } else setUserFilter({ ...userFilter, enabled: undefined })
        }}
      />
      <SaivaRadioButton
        value={
          userFilter && 
          userFilter?.enabled ? ['enabled'] :
          []
        }
        name="enabled"
        width={'80px'}
        height={24}
        inputType="checkbox"
        returnType="string"
        label={''}
        values={[{ value: 'enabled', label: 'Enabled' }]}
        onChange={(e) => {
          if (e.includes('enabled')) {
            setUserFilter({ ...userFilter, enabled: true })
          } else setUserFilter({ ...userFilter, enabled: undefined })
        }}
      />
    </div>
  </div>
  )

  const isUserFilterActive = !!(userFilter &&
    (userFilter.roles?.length || userFilter.facilityIds?.length || userFilter.capabilities?.length || userFilter.regions?.length));

  const mailLink = `mailto:support@saivahc.com?subject=Request more users for ${userContext.currentOrg.id}`

  return (
    <div id="adminUsers" className='container-fluid'>
      <Modal
        closeIcon={
          <CrossIcon
            style={{ padding: '4px' }}
            onClick={() => setOpenError(false)}
          />
        }
        open={openError}
        footer={null}
        maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
        }}
        closable={true}
        width={'342px'}
      >
        <div className={styles.errorModalTitle}>
          <h3><InfoIcon />{t('users.modal.userCapacity.title')}</h3>
          {t('users.modal.userCapacity.text')}
          <a href={mailLink}> support@saivahc.com </a>
          {t('users.modal.userCapacity.text2')}
        </div>
      </Modal>
      <FormModal isOpen={showCreateUserModal} close={handleClose} title={userFormModalTitle} editingUser={editingUser} editInvitation={false}/>
      <div className='row justify-content-between d-flex flex-md-row flex-column-reverse'>
        <div className='col-1 col-md-3 d-flex justify-content-end'>
        </div>
        <div className='col-12 col-md-5 d-flex justify-content-end'>
          <SaivaSearch
            placeholder={t('search.text')}
            filters={true}
            activeFilters={isUserFilterActive}
            onChange={(e) => {
              setUserFilter({...userFilter, search: e.target.value})
            }}
            value={userFilter?.search}
            content={filters}
          />
        </div>
        <div className='col-12 col-md-3 d-flex justify-content-start justify-content-md-end mb-3 mb-md-0'>
          <AccessControl userPermissions={[UserPermission.INVITATIONS_CREATE]}>
            <button className="primary-text-button" onClick={() => createUser()}>
              <PlusIcon style={{stroke: '#4070DC'}}/>
              {inviteUserTitle}
            </button>
          </AccessControl>
        </div>
      </div>
      <div className='row'>
        <div className='col table-responsive pb-4' style={{display: 'grid'}}>
          <table className='mt-3 table table-hover saiva'>
            <thead>
              <tr className=''>
                {makeSortableTableHeadCell(t('users.table.header.name.label'), "name")}
                {makeSortableTableHeadCell(t('users.table.header.email.label'), "email")}
                {makeSortableTableHeadCell(t('users.table.header.role.label'))}
                {makeSortableTableHeadCell(t('users.table.header.title.label'), "title")}
                {makeSortableTableHeadCell(t('users.table.header.capability.label'))}
                {makeSortableTableHeadCell(t('users.table.header.facility.label'))}
                {makeSortableTableHeadCell(t('users.table.header.region.label'))}
                {makeSortableTableHeadCell(t('users.table.header.lastActive.label'), "last_active_at")}
                <AccessControl userPermissions={[UserPermission.USERS_EDIT]} >
                  <th>{t('users.table.header.action.label')}</th>
                </AccessControl>
              </tr>
            </thead>
            <tbody className=''>
              {requestInFlight ? <tr>
                <td colSpan={columnCount} className="text-center">
                  <img className="me-2" src={Gradient} width="40px" height="40px" />
                  <p className='text-muted'>{t('loading.text')}</p>
                </td>
              </tr> :
              users.length === 0 && <tr>
                <td colSpan={columnCount} className="text-center">
                  <p className='text-muted'>{t('users.table.empty.text')}</p>
                </td>
              </tr>}
              {(userContext.hasPermission(UserPermission.USERS_EDIT)) &&
                users.map((u) => makeUserRow(u))
              }
            </tbody>
          </table>
          <SaivaPagination
            total={itemCount}
            page={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      </div>
    </div>
  )
}

