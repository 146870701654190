import { UseFormReturn } from 'react-hook-form'
import { Row, Col, Button } from 'antd'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserCapability, UserPermission } from 'types/user-types'
import scssVariables from 'styles/variables.module.scss'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import UserFacilitySelect from 'components/SaivaForm/SaivaMultiSelect/UserFacilitySelect'
import { SaivaUser } from 'types/user-types'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { SaivaInvitation } from 'types/invitation-types'
import { useUserContext } from 'context/UserContext'

interface Props {
  form: UseFormReturn<any>
  defaultValues: SaivaUser.User | undefined
  options: any
  isEdit: boolean
  apiError: any
  onSubmit: (data: SaivaInvitation.Request) => any
  handleDelete: () => void
}

type SelectOption = {
  label: string
  value: string
  color?: string
}

const FormLayout = ({
  form,
  options,
  defaultValues,
  isEdit,
  apiError,
  onSubmit,
  handleDelete
}: Props) => {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const { formState, handleSubmit, control, setValue, getValues, watch } = form
  const { errors, isSubmitting, isValid } = formState
  const { roles, titles, facilities, regions } = options

  const CAPABILITY_EMAIL: SelectOption = {
    value: UserCapability.EMAIL_NOTIFICATION,
    label: t('users.capability.email'),
    color: scssVariables.capabilityEmail
  }
  const CAPABILITY_PUSH: SelectOption = {
    value: UserCapability.PUSH_NOTIFICATION,
    label: t('users.capability.push'),
    color: scssVariables.capabilityPush
  }
  const CAPABILITY_WEB: SelectOption = {
    value: UserCapability.WEB_APP_LOGIN,
    label: t('users.capability.webapp'),
    color: scssVariables.capabilityWeb
  }
  const CAPABILITY_MOBILE: SelectOption = {
    value: UserCapability.MOBILE_APP_LOGIN,
    label: t('users.capability.mobileapp'),
    color: scssVariables.capabilityMobile
  }

  const capabilities =
    userContext.currentRole === 'medical_supply'
      ? [CAPABILITY_WEB]
      : [CAPABILITY_EMAIL, CAPABILITY_PUSH, CAPABILITY_WEB, CAPABILITY_MOBILE]

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Row gutter={[16, 16]} align="top">
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('invitedUsers.form.placeholder.email')}
                label={t('invitedUsers.form.label.email')}
                name="email"
                errors={errors.email}
                required={true}
                onChange={(e) => {
                  setValue('email', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('invitedUsers.form.placeholder.name')}
                label={t('invitedUsers.form.label.name')}
                name="name"
                errors={errors.name}
                required={true}
                onChange={(e) => {
                  setValue('name', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="title"
            render={({ field: { value } }) => {
              return (
                <SaivaSelect
                  value={value}
                  options={titles.map((r) => {
                    return { value: r.name, label: r.description }
                  })}
                  label={t('invitedUsers.form.label.title')}
                  placeholder={t('invitedUsers.form.placeholder.title')}
                  icon={null}
                  errors={errors.title}
                  required={true}
                  onChange={(e) => {
                    setValue('title', e)
                  }}
                />
              )
            }}
          />
        </Col>
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="roles"
            render={({ field: { value } }) => {
              return (
                <SaivaSelect
                  value={value.map((item) => {
                    return item.description
                  })}
                  options={roles
                    .filter(
                      (role) => role.role_category === userContext.currentRole
                    )
                    .map((r) => {
                      return { value: r.name, label: r.description }
                    })}
                  label={t('invitedUsers.form.label.roles')}
                  placeholder={t('invitedUsers.form.placeholder.roles')}
                  icon={null}
                  disabled={isEdit && userContext.currentRole === 'medical_supply'}
                  errors={errors.roles}
                  required={true}
                  reset={() => setValue('roles', [])}
                  onChange={(e) => {
                    setValue(
                      'roles',
                      roles.filter((item) => item.name == e)
                    )
                  }}
                />
              )
            }}
          />
        </Col>
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="capabilities"
            render={({ field: { value } }) => {
              return (
                <SaivaSelect
                  value={value}
                  options={capabilities}
                  label={t('invitedUsers.form.label.capabilities')}
                  placeholder={t('invitedUsers.form.placeholder.capabilities')}
                  multiselect={true}
                  icon={null}
                  reset={() => setValue('capabilities', [])}
                  onChange={(e) => {
                    setValue('capabilities', e)
                  }}
                />
              )
            }}
          />
        </Col>
        <Col md={12} xs={24}>
          <Controller
            control={control}
            name="regions"
            render={({ field: { value } }) => {
              return (
                <UserFacilitySelect
                  value={value}
                  name="regions"
                  label={t('invitedUsers.form.label.access_facilities')}
                  reset={() => setValue('regions', [])}
                  values={facilities}
                  parents={regions}
                  errors={errors.regions}
                  required={true}
                  disabled={regions.length > 0 ? false : true}
                  onChange={(e) => {
                    setValue('regions', e)
                  }}
                />
              )
            }}
          />
        </Col>
      </Row>
      {apiError && (
        <>
          <div className={`banner bannerFailed`}>
            <InfoIcon width={'46px'} style={{ padding: '2px' }} />
            {t(apiError)}
          </div>
        </>
      )}
      <Row justify={'end'} style={{ marginTop: '32px' }}>
        <Col style={{ marginRight: '16px' }}>
          <button
            className={'primary-text-button button-base-small'}
            onClick={handleDelete}
          >
            {t('invitedUsers.form.cancel.button')}
          </button>
        </Col>
        <Col>
          <Button
            disabled={isSubmitting}
            className={'primary-button button-base-small'}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit
              ? t('invitedUsers.form.edit.button')
              : t('invitedUsers.form.submit.button')}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default FormLayout
